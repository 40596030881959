import {
  Box,
  Container,
  Button,
  styled,
  Grid,
  TextField,
} from '@material-ui/core'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { navigate, PageProps } from 'gatsby'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import AddIcon from '@mui/icons-material/Add'
import { toast } from 'react-toastify'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import PageHeader from '../../../components/page-header'
import MobileNavbar from '../../../components/navbar/mobile'
import Navbar from '../../../components/navbar'
import { useCart } from '../../../hooks/useCart'

const ttrButtonPrimary = {
  backgroundColor: '#008174',
  fontWeight: 'bold' as 'bold',
  padding: '12px',
  width: '300px',
  marginRight: '5px',
}

const skipStepButtonStyles = {
  fontWeight: 'bold' as 'bold',
  color: '#008174',
  borderColor: '#008174',
  borderWidth: '3px',
  padding: '10px',
  width: '300px',
}

const addMoreEmailButtonStyles = {
  float: 'left' as 'left',
  fontWeight: 'bold' as 'bold',
  color: '#008174',
  borderColor: '#008174',
  marginTop: '10px',
  borderWidth: '1px',
}

const FloatFixer = styled('div')`
  clear: both;
`
const StyledInviteButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        marginBottom: '1rem',
      },
    }
  }}
`
const StyledBox = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center',
      },
    }
  }}
  margin-top: 40px;
  text-align: right;
`

const RegisterTeamInvitePage = (props: PageProps) => {
  const cartType = 'team'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })

  const [emails, setEmails] = useState([{ id: uuidv4(), value: '' }])

  const { cart } = useCart({ type: cartType })

  const handleAddMore = () => {
    setEmails([...emails, { id: uuidv4(), value: '' }])
  }

  const handleEmailChange = (str: string, index: number) => {
    const items = [...emails]
    const item = items[index]
    item.value = str
    items[index] = item
    setEmails(items)
  }

  const onSuccess = () => {
    navigate('/register/team/success')
  }

  const sendEmailInvites = async () => {
    // TODO - refactor to use mutation
    try {
      if (cart?.data?.competition) {
        if (emails.length) {
          await axios
            .post(`https://api.tritagrugby.com/teams/send-email-invites`, {
              emails: emails.map((email: any) => email.value),
              teamName: cart?.data?.teamDetails?.teamName,
              competitionName: cart?.data?.competition?.name || '',
              assocName: cart?.data?.competition?.association.name || '',
            })
            .catch(err => alert(JSON.stringify(err)))

          toast.success('Invites sent successfully')
          onSuccess()
        } else {
          toast.error('Please input at least one email')
        }
      }
    } catch (error) {
      toast.error('Something went wrong, Please try again')
    }
  }

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <Container
        sx={{
          marginTop: '60px',
        }}
      >
        <Box
          sx={{
            marginTop: '20px',
          }}
        >
          <PageHeader title="Invite your team" />
        </Box>
        <Box sx={{}}>
          <div
            style={{
              lineHeight: '25px',
              color: '#3C3835',
            }}
          >
            Click on copy link to get a unique registration link to share with
            your teammates
          </div>
          <div
            style={{
              marginTop: '20px',
            }}
          >
            <Button
              variant="contained"
              fullWidth
              style={ttrButtonPrimary}
              onClick={() => {
                // TODO - Refactor this to get team name from url
                const teamDetails = cart?.data?.teamDetails
                if (teamDetails) {
                  navigator.clipboard.writeText(
                    `https://tritagrugby.com/register/player/venue/?q=${encodeURIComponent(
                      teamDetails.teamName,
                    )}`,
                  )
                  toast.success('Copied to Clipboard!')
                }
              }}
            >
              Copy Link
            </Button>
          </div>
        </Box>
        <Box
          component="div"
          sx={{
            height: '5px',
            backgroundColor: '#F5821F',
            margin: '30px 0',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: '50%',
              fontWeight: 'bold',
              backgroundColor: '#f5f6fa',
              padding: '5px 10px',
              transform: 'translateX(-50%)',
              marginTop: '-12px',
            }}
          >
            or
          </div>
        </Box>
        <Box component="div">
          <div
            style={{
              lineHeight: '25px',
              color: '#3C3835',
            }}
          >
            Enter your teammates email address below to receive an invite link
          </div>
          <Box
            component="form"
            onSubmit={() => console.log('sent')}
            sx={{
              marginTop: '30px',
            }}
          >
            <Grid container spacing={2}>
              {emails.map((email: any, i: number) => (
                <Grid item xs={12} key={email.id}>
                  <TextField
                    label="Email"
                    variant="filled"
                    size="small"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    onChange={e => handleEmailChange(e.target.value, i)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <div
            style={{
              marginTop: '5px',
            }}
          >
            <Button
              variant="outlined"
              size="small"
              style={addMoreEmailButtonStyles}
              onClick={() => {
                handleAddMore()
              }}
            >
              <AddIcon />
            </Button>
            <div
              style={{
                float: 'left',
                margin: '16px 0 0 10px',
              }}
            >
              Add more
            </div>
            <FloatFixer />
          </div>
        </Box>
        <StyledBox>
          <StyledInviteButton
            variant="contained"
            style={ttrButtonPrimary}
            onClick={sendEmailInvites}
            disabled={false} // TODO - Update to isLoading once using mutation
          >
            Send email invites
          </StyledInviteButton>
          <Button
            variant="outlined"
            style={skipStepButtonStyles}
            onClick={() => onSuccess()}
            disabled={false} // TODO - Update to isLoading once using mutation
          >
            Skip this step
          </Button>
        </StyledBox>
      </Container>
    </>
  )
}

export default withAuthenticationRequired(RegisterTeamInvitePage)
